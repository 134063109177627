import React from 'react'
import SEO from '../components/generic/Seo'
import { getFavorites } from '../lib/favorites_hook'
import { useMaterialsData } from '../lib/materials_hook'
import { ProductMaterial } from '../types'
import { useProductsByIdData } from '../lib/products_by_ids_hooks'
import ProductPreview from '../components/product/Preview'
import LinearProgress from '@mui/material/LinearProgress'
import FavoritesShareButton from './../components/FavoritesShareButton'
import { isConnectedMaterial, mapMaterialToApiGroup } from '../utils/Mapping'
import { useTranslation } from 'react-i18next'
import { getHash } from '../utils/Crypto'

const FavoritesPage = (props: any) => {
  const [user, setUser] = React.useState()
  const [favorites, setFavorites] = React.useState(getFavorites())

  const allMaterialsData = useMaterialsData()

  const { t } = useTranslation('translation')
  // const urlParts: any = []

  let display: any = []
  const productsToFetch: any = []

  favorites.map((favorite: any) => {
    productsToFetch.push({
      material: favorite.material,
      motiveId: '' + favorite.id,
      ratio: '' + favorite.ratio,
    })

    // if (urlParts.indexOf(favorite.material) === -1) {
    //   urlParts[favorite.material] = mapInternalKey2Slug(favorite.material)
    // }
  })

  const p = useProductsByIdData(productsToFetch)

  favorites.map((favorite: any) => {
    const m = allMaterialsData.filter((materialData: ProductMaterial) => {
      return isConnectedMaterial(materialData, favorite.material)
    })

    if (typeof p !== 'undefined') {
      const mappedMaterial = mapMaterialToApiGroup(favorite.material)

      if (
        typeof p[favorite.id] !== 'undefined' &&
        typeof p[favorite.id][mappedMaterial] !== 'undefined' &&
        typeof p[favorite.id][mappedMaterial][favorite.ratio] !== 'undefined'
      ) {
        const elem = {
          productData: p[favorite.id][mappedMaterial][favorite.ratio],
          materialData: m,
          material: favorite.material,
          signature: '',
          // url: '',
        }

        elem.signature = getHash(elem)

        display.push(elem)
      } else {
        console.log('Missing ' + favorite.id + ' (' + mappedMaterial + ')')
      }
    }
  })

  // Motives missing?
  if (favorites.length != display.length && typeof p !== 'undefined') {
    // TODO what to do with missing favorites?
    console.log('Some favorites are missing!')
    console.log('Favorites: ' + favorites.length)
    console.log('Found: ' + display.length)
  }

  display = display.reverse()

  const isLoading = typeof p === 'undefined'

  if (isLoading) {
    return (
      <>
        <SEO title="Deine artboxONE Favoriten" />
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>{t('Favoriten')}</h1>
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else if (display.length == 0) {
    return (
      <>
        <SEO title="Deine artboxONE Favoriten" />
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>{t('Favoriten')}</h1>
              <p>
                {t(
                  'Du hast noch keine Favoriten angelegt. Tippe dazu auf den Übersichtsseiten auf die Herzen.',
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <SEO title="Deine artboxONE Favoriten" />
        <div className="container">
          <div className="row">
            <div className="col-md">
              <h1>Favoriten</h1>
            </div>
            <div className="col-md" style={{ textAlign: `right` }}>
              {user && <FavoritesShareButton />}
            </div>
          </div>
          <div className="row">
            {display.map((p: any) => (
              <div className="col-6 col-sm-4" key={p.signature}>
                <ProductPreview
                  material={p.material}
                  materials={p.materialData}
                  productData={p.productData}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default FavoritesPage
